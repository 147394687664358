<template>
	<Layout>
		<v-container fluid v-if="loading">
			<v-row height="100vh" justify="center" align="center">
				<v-col cols="12" class="text-center"><div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></v-col>
				<v-col cols="12"><h3 class="text-center">Cargando información... </h3></v-col>
			</v-row>
		</v-container>
		<v-container v-else>
			<v-row>
				<v-col cols="12">
					<h2> <v-icon large>mdi-circle-edit-outline</v-icon> EDITAR MIS DATOS</h2>
				</v-col>
			</v-row>
			<v-form ref="form" v-model="formValidity">

				<v-row>
					<v-col cols="12" sm="2">
						<qr-code
							:size="150"
							:text="`https://miembros.svderma.org/validar/${user._id}`"
							color="#000"
							bg-color="#fff"
							error-level="L"
						/>
						<div class="code-member">{{user._id}}</div>
					</v-col>
					<v-col cols="12" sm="5">
						<h3>INFORMACIÓN PERSONAL</h3>
						<v-text-field
							type="number"
							prepend-icon="mdi-shield-account-outline"
							label="Cedula Identidad"
							:rules="[rules.required]"
							v-model="user.cedula"
							hint="Escriba solo números"
							persistent-hint
						/>
						<v-text-field
							prepend-icon="mdi-account-reactivate-outline"
							label="Nombre"
							v-model="user.nombre"
							:rules="[rules.required]"
						/>
						<v-text-field
							prepend-icon="mdi-account-reactivate-outline"
							label="Apellido"
							v-model="user.apellido"
							:rules="[rules.required]"
						/>
						<v-row>
							<v-col cols="12" sm="6">
								<v-select
									v-model="user.sexo"
									:items="['Femenino','Masculino']"
									prepend-icon="mdi-circle-slice-4"
									menu-props="auto"
									hide-details
									label="Sexo"
									:rules="[rules.required]"
									class="mb-5"
								/>
							</v-col>
							<v-col cols="12" sm="6">
							<v-text-field
								type="tel"
								prepend-icon="mdi-phone"
								label="Celular Contacto"
								v-model="user.telefono"
								:rules="[rules.required]"
							/>
							</v-col>
						</v-row>
						<v-text-field
							prepend-icon="mdi-email-outline"
							label="Correo electrónico"
							v-model="user.email"
							:rules="[rules.required, rules.email]"
						/>


						<v-text-field
							prepend-icon="mdi-hospital-building"
							label="Lugar de trabajo"
							v-model="user.trabajo"
						/>
						<v-textarea
							dense
							outlined
							prepend-icon="mdi-map-marker-radius-outline"
							v-model="user.direccion"
							label="Dirección de Contacto"
							:value="user.direccion"
							rows="3"
							:rules="[rules.required]"
						/>
						<v-row>
							<v-col cols="12" sm="6">
								<v-text-field
									prepend-icon="mdi-home-city-outline"
									label="Ciudad"
									v-model="user.ciudad"
									:rules="[rules.required]"
								/>
							</v-col>
							<v-col cols="12" sm="6">
								<!-- <v-text-field
									prepend-icon="mdi-city-variant-outline"
									label="Estado"
									v-model="user.estado"
									:rules="[rules.required]"
								/> -->

								<v-select
								v-model="user.estado"
								:items="estados"
								prepend-icon="mdi-city-variant-outline"
								menu-props="auto"
								hide-details
								label="Estado"
								:rules="[rules.required]"
							/>




							</v-col>
						</v-row>
						<v-text-field
							prepend-icon="mdi-flag-outline"
							label="País"
							v-model="user.pais"
							:rules="[rules.required]"
						/>
						<div class="my-5">
							<v-divider />
						</div>
						<h3>ACTUALIZACIÓN DE CONTRASEÑA</h3>
						<v-text-field
							type="password"
							color="primary"
							v-model="password"
							label="Contraseña (Deje en blanco si no desea modificar)"
							dense
							outlined />
					</v-col>
					<v-col cols="12" sm="5">
						<h3 class="mb-3">INFORMACIÓN PÚBLICA</h3>
						<v-textarea
							dense
							outlined
							prepend-icon="mdi-map-marker-radius-outline"
							v-model="user.direccion_consulta_principal"
							label="Dirección de Consulta Principal"
							:value="user.direccion_consulta_principal"
							rows="3"
						/>
						<v-text-field
							type="tel"
							prepend-icon="mdi-phone"
							label="Teléfono(s) para citas Principal"
							v-model="user.telefono_consulta_principal"
							class="mb-5"
						/>
						<v-textarea
							dense
							outlined
							prepend-icon="mdi-map-marker-radius-outline"
							v-model="user.direccion_consulta_secundaria"
							label="Dirección de Consulta Alternativa"
							:value="user.direccion_consulta_secundaria"
							rows="3"
						/>
						<v-text-field
							type="tel"
							prepend-icon="mdi-phone"
							label="Teléfono(s) para citas Alternativo"
							v-model="user.telefono_consulta_secundaria"
						/>


						<div class="my-5">
							<v-divider />
						</div>
						<h3>Fotografía</h3>
						<div class="pa-3 d-flex flex-column justify-center align-center">
							<v-img v-if="file == null" :src="user.photo" width="300" contain />
							<v-img v-else :src="image" width="300" contain />
							<v-sheet dark width="300px">
								<!-- <v-file-input
									class="mt-2"
									v-model="file"
									label="Seleccione Foto"
									accept="image/*"
									prepend-icon="mdi-camera"
									@change="previewPhoto($event)"
								/> -->
								<v-btn block depressed tile color="primary" @click="$refs.boton.click()"><v-icon left>mdi-camera</v-icon> Buscar imagen</v-btn>

									<input type="file" ref="boton" class="d-none" @change="previewImage($event)">
							</v-sheet>
						</div>
					</v-col>
 					<v-col cols="12">
						<div class="text-center my-5">
							<v-btn
								class="mr-4"
								:to="{name: 'Dashboard'}"
								outlined
								text
								color="grey darken-3">
								REGRESAR
							</v-btn>

							<v-btn
								x-large
								@click.stop="updateUser()"
								:loading="saving"
								:disabled="!formValidity"
								rounded
								color="primary accent-3">
								ACTUALIZAR
							</v-btn>
						</div>
					</v-col>
				</v-row>

			</v-form>
		</v-container>
	</Layout>
</template>

<script>
	import { mapState } from 'vuex';
	import axios from "axios";
	import store from '@/store';
	const header = {"Token": store.state.userModule.token}
	const configuration = {headers: header}
	export default {
		name: 'EditUser',
		data: () =>({
			password: '',
			loading: false,
			saving: false,
			formValidity: false,
			direccionTitulo: 'Dirección',
			rules: {
				required: value => !!value || 'Required.',
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || 'Invalido email.'
				},
				password: value => value.length >= 6 || 'Contraseña obligatoria',
			},
			user: {
				cedula: '',
				nombre: '',
				apellido: '',
				sexo: '',
				email: '',
				telefono: '',
				trabajo: '',
				direccion: '',
				ciudad: '',
				estado: '',
				pais: 'Venezuela',
				direccion_consulta_principal: '',
				telefono_consulta_principal: '',
				direccion_consulta_secundaria: '',
				telefono_consulta_secundaria: '',
				photo: '',
			},
			image: null,
			file: null,
			estados: [
			'Amazonas',
			'Anzoategui',
			'Apure',
			'Aragua',
			'Barinas',
			'Bolivar',
			'Carabobo',
			'Cojedes',
			'Distrito Capital',
			'Delta Amacuro',
			'Falcon',
			'Guarico',
			'La Guaira',
			'Lara',
			'Merida',
			'Miranda',
			'Monagas',
			'Nueva Esparta',
			'Portuguesa',
			'Sucre',
			'Tachira',
			'Trujillo',
			'Yaracuy',
			'Zulia',
			'Exterior'
		]

		}),
		mounted(){
			this.getData()
		},
		methods: {
			async getData(){
				this.loading = true
				const id = this.$store.state.userModule.user._id
				let me = this;
				await axios.get(`user/query?_id=${id}` ,configuration)
					.then(function (response) {
						me.user._id = id,
						me.user.cedula = response.data.cedula,
						me.user.nombre = response.data.nombre,
						me.user.apellido = response.data.apellido,
						me.user.sexo = response.data.sexo,
						me.user.email = response.data.email,
						me.user.telefono = response.data.telefono,
						me.user.trabajo = response.data.trabajo,
						me.user.direccion = response.data.direccion,
						me.user.ciudad = response.data.ciudad,
						me.user.estado = response.data.estado,
						me.user.pais = response.data.pais,
						me.user.photo = response.data.photo || 'https://res.cloudinary.com/svdmqe-cloud/image/upload/v1635886550/pictures/user_ciunta.jpg',
						me.user.direccion_consulta_principal = response.data.direccion_consulta_principal,
						me.user.telefono_consulta_principal = response.data.telefono_consulta_principal,
						me.user.direccion_consulta_secundaria = response.data.direccion_consulta_secundaria,
						me.user.telefono_consulta_secundaria = response.data.telefono_consulta_secundaria
						me.loading = false
					})
					.catch(function (e) {
						console.log(e.message)
					})
			},
			async updateUser() {
				this.saving = true
				let me = this;
				if (this.file != null){
					const response = await this.uploadPhoto(this.file)
					this.user.photo = await response.url
					this.file = null
					this.image = null
				}
				await axios.put('user/profile', {
					_id: me.user._id,
					cedula: me.user.cedula,
					nombre: me.user.nombre,
					apellido: me.user.apellido,
					sexo: me.user.sexo,
					email: me.user.email,
					telefono: me.user.telefono,
					trabajo: me.user.trabajo,
					especialidad: '',
          institucion: '',
					direccion: me.user.direccion,
					ciudad: me.user.ciudad,
					estado: me.user.estado,
					pais: me.user.pais,
					password: me.password,
					photo: me.user.photo,
					direccion_consulta_principal: me.user.direccion_consulta_principal,
					telefono_consulta_principal: me.user.telefono_consulta_principal,
					direccion_consulta_secundaria: me.user.direccion_consulta_secundaria,
					telefono_consulta_secundaria: me.user.telefono_consulta_secundaria
				}, configuration)
					.then(function(response){
						me.$store.commit('SET_ALERT_MESSAGE', {
							show: true,
							color: 'success',
							message: 'Perfil actualizado satisfactoriamente',
							timeout: 4000
						}, {root: true})
						me.saving = false
						me.password = ''
					})
					.catch(function (e) {
						me.saving = false
						console.log(e.message)
					})
			},
			previewImage(event){
				const extensionFile = event.target.files[0].type;
				if(extensionFile === 'image/jpeg' || extensionFile === 'image/png'){
         	this.file = event.target.files[0]
				 	const reader = new FileReader();
					reader.readAsDataURL(this.file);
					reader.onload = (e) => {
						this.image = e.target.result
					}
				}else{
					alert('Archivo no válido')
					this.file = null
					return
				}
			},
			async uploadPhoto (file) {
				let me=this
				return new Promise(function (resolve, reject) {
					const CLOUDINARY_URL = process.env.VUE_APP_CLOUDINARY_URL;
					const CLOUDINARY_UPLOAD_PRESET = process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET;

					let formData = new FormData();
					formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
					formData.append('folder', 'miembros');
					formData.append('file', file);
					let request = new XMLHttpRequest();
					request.open('POST', CLOUDINARY_URL, true);
					request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
					request.onreadystatechange = () => {
						if (request.readyState === 4 && request.status === 200) {
							let response = JSON.parse(request.responseText);
							resolve(response);
						}
						if (request.status !== 200) {
							let response = JSON.parse(request.responseText);
							let error = response.error.message;
							alert('error, status code not 200 ' + error);
							reject(error);
						}
					};
					request.onerror = (err) => {
						alert('error: ' + err);
						reject(err);
						me.saving = false
					};
					request.send(formData);
			})
  }

		},
		computed: {
		...mapState(['alert']),
		}

	}
</script>
<style>
	.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 180px;
  height: 180px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: rgb(2, 3, 71);
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.code-member {
	font-size:0.7em;
}

</style>
